<template>
    <div class="custom-modal-container">
        <div class="custom-modal">
            <div class="custom-modal-header">
                <div class="text-title">{{ title }}</div>
                <div class="close-button" @click="$emit('close')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <path d="M12 1.20857L10.7914 0L6 4.79143L1.20857 0L0 1.20857L4.79143 6L0 10.7914L1.20857 12L6 7.20857L10.7914 12L12 10.7914L7.20857 6L12 1.20857Z" fill="white" />
                    </svg>
                </div>
            </div>
            <div class="custom-modal-content" :class="{ 'with-scroll': hasScroll }">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CustomModal',
    props: {
        title: {
            type: String,
            default: 'Título del Modal'
        },
        // Nueva prop para controlar el scroll desde fuera
        forceScroll: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hasScroll() {
            // Puedes implementar lógica más sofisticada aquí si lo necesitas
            return this.forceScroll
        }
    }
}
</script>

<style lang="scss" scoped>
.custom-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
    z-index: 1000;
}

.custom-modal {
    background-color: $color-white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    width: 90%; /* Ancho por defecto */
    max-width: 450px; /* Máximo solicitado */
    max-height: 90vh;
    display: flex;
    flex-direction: column;
}

.custom-modal-header {
    padding: 1rem;
    border-bottom: 1px solid $color-white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-primary-500;
    color: $color-white;
    font-family: $text-bold;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    .text-title {
        font-size: 20px;
        color: $color-white;
        font-family: $text-bold;
    }
}

.close-button {
  cursor: pointer;
}

.custom-modal-content {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}

</style>
